import * as styles from './style.module.css';
import sites from './sites.json';

(function() {
    if (typeof window === 'object') {
        window.addEventListener('load', function() {
            let storage: Storage;
            if (typeof window.localStorage === 'object') {
                storage = window.localStorage
            }  else {
                return;
            }

            if (window.location.search.slice(1).split('&').indexOf('noban') >= 0) {
                return;
            }

            const imageUrl = new URL('logo.svg', import.meta.url);
            const doc = document.querySelector('body');

            const siteUrl = typeof process === 'object' && process.env.NODE_ENV === 'development' ? '' : 'https://demo-banner.circul8.com.au'
            if (siteUrl) {
                const link = document.createElement('link');
                link.href = `${siteUrl}/index.css?t=${Date.now()}`;
                link.rel = 'stylesheet';
                document.head.append(link);
            }

            let sitesHtml = '';
            for (const client in sites) {
                sitesHtml += `<optgroup label="${client}">`;
                for (const project in sites[client]) {
                    const url = sites[client][project];
                    sitesHtml += `<option value="${url}">${project}</option>`;
                }
                sitesHtml += `</optgroup>`;
            }

            let html = `
                <div class="${styles.banner}">
                    <div class="${styles.banner__container}">
                        <div class="${styles.banner__left}">
                            <a href="https://www.circul8.com.au/?utm_source=demosite" target="_blank"><img src="${imageUrl}" width="56" height="56" class="${styles.banner__logo}"></a>
                            <p class="${styles.banner__copy}">
                                This is an archived website to showcase work created by <a href="https://www.circul8.com.au/?utm_source=demosite" target="_blank">Circul8</a>.
                            </p> 
                        </div>
                        <div class="${styles.banner__right}">
                            <div class="${styles.banner__dropdown}">
                                <span>View more sites</span>
                                <select>
                                    <option value="">-- select --</option>
                                    ${sitesHtml}
                                </select>
                            </div>
                            <button class="${styles.banner__close}">&times;</button>
                        </div>
                    </div>
                    <button class="${styles.banner__open}">
                        <img src="${imageUrl}">
                        <span class="${styles.banner__rsaquo}">&rsaquo;</span>
                    </button>
                </div>
`;

            doc!.insertAdjacentHTML('beforeend', html);

            let stamp: number = Number.parseInt(storage.getItem('__c8-demo-banner') ?? '');
            if (!Number.isNaN(stamp) && (Date.now() - stamp) <= 3600 * 1000) { // 1hr
                document.querySelector(`.${styles.banner}`)!.classList.add(`${styles['banner--hidden']}`);
            }

            // Other demos
            const othersBtn = document.querySelector(`.${styles.banner__dropdown}`);
            othersBtn!.addEventListener('change', (event) => {
                event.preventDefault();
                const {value: url} = event.target! as HTMLSelectElement;
                if (url) {
                    window.open(url);
                }
            });

            // Close button
            const closeBtn = document.querySelector(`.${styles.banner__close}`);
            closeBtn!.addEventListener('click', (event) => {
                event.preventDefault();
                storage.setItem('__c8-demo-banner', Date.now().toString());
                document.querySelector(`.${styles.banner}`)!.classList.add(`${styles['banner--hidden']}`);
            });

            // Open button
            const openBtn = document.querySelector(`.${styles.banner__open}`);
            openBtn!.addEventListener('click', (event) => {
                event.preventDefault();
                storage.removeItem('__c8-demo-banner');
                document.querySelector(`.${styles.banner}`)!.classList.remove(`${styles['banner--hidden']}`);
            });
        });
    }
})();
