{
  "Amnesty International": {
    "Make the Choice": "http://amnesty.demo.circul8.com.au/"
  },
  "Australian Museum": {
    "Sydney Science Trail - 2020": "https://sst-2020.circul8.app/",
    "Sydney Science Trail - 2021": "https://sst-2021.circul8.app/",
    "Sydney Science Trail - 2022": "https://sst-2022.circul8.app/",
    "Recovery Campaign": "https://recovery-campaign.circul8.app/"
  },
  "Centennial Parklands": {
    "Swamp to Scrumb - 2021": "https://swamptoscrub.circul8.app/",
    "Swamp to Scrumb - 2022": "https://swamptoscrub2022.circul8.app/"
  },
  "Circul8": {
    "Enrich website": "https://enrich.circul8.app/",
    "2020 Vision Test": "https://2020.circul8.com.au/"
  },
  "Craft Irish Whiskey": {
    "Website": "https://craft-irish-whiskey.circul8.app/"
  },
  "Japan National Tourism Organization": {
    "Unseen Japan": "http://unseen-japan.demo.circul8.com.au/",
    "Made from many moments": "https://jnto-mfmm.circul8.app/",
    "Hidden Beauty of Japan": "https://jnto-hiddenbeauty.circul8.app/",
    "Secret Scrolls of Ikigai": "https://jnto-ikigai.circul8.app/",
    "Discovery Dial": "https://jnto-discoverydial.circul8.app/"
  },
  "NSW DPI": {
    "Biosecurity Warrior": "https://biosecurity-warrior.circul8.app/"
  },
  "NSW Government": {
    "Responsible Gambling Website": "https://rgaw.circul8.app/",
    "Checkmates Trivia": "https://rgaw-trivia.circul8.app/"
  },
  "Prince of Wales Hospital": {
    "Donation site": "https://pow.circul8.app/",
    "Wall of Giving": "https://pow-wall.circul8.app"
  },
  "Tahiti": {
    "Diving site": "https://tahiti-staging.circul8.app/?noban"
  },
  "Western Sydney University": {
    "Quiz": "http://nyresolutionquizwsu-com.demo.circul8.com.au/"
  }
}
